import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import './BingoCard.css';
import images from './images'; // Import your image assets

const BingoCard = () => {
  const shuffleArray = (array) => {
    let newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const [shuffledImages, setShuffledImages] = useState(shuffleArray(images));
  const cardRef = useRef();

  const handleShuffle = () => {
    setShuffledImages(shuffleArray(images));
  };

  const handlePrint = useReactToPrint({
    content: () => cardRef.current,
  });

  return (
    <div>
      <div className="bingo-card" ref={cardRef}>
        <div className="grid">
          {shuffledImages.slice(0, 25).map((image, index) => (
            <div key={index} className="grid-item">
              <img src={image} alt={`Bingo item ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <button onClick={handleShuffle}>Generar Nueva</button>
      <button onClick={handlePrint}>Imprimir</button>
    </div>
  );
};

export default BingoCard;