import React from 'react';
import BingoCard from './components/BingoCard';

function App() {
  return (
    <div className="App">
      <BingoCard />
    </div>
  );
}

export default App;
